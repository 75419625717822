#blogads {
  height: 200px;
  background-color: #ffffff;
  border-radius: 8px;
}

.blog-listing {
  padding-top: 30px;
  padding-bottom: 30px;

}

.bloggray-bg {
  background-color: #f5f5f5;
}

/* Blog 
---------------------*/
.blog-grid {
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  margin-top: 15px;
  margin-bottom: 15px;
}

.blog-grid .blog-img {
  position: relative;
}

.blog-grid .blog-img .date {
  position: absolute;
  background: #ffcc00;
  color: #ffffff;
  padding: 8px 15px;
  left: 10px;
  top: 10px;
  border-radius: 4px;
}

.blog-grid .blog-img .date span {
  font-size: 22px;
  display: block;
  line-height: 22px;
  font-weight: 700;
}

.blog-grid .blog-img .date label {
  font-size: 14px;
  margin: 0;
}

.blog-grid .blog-info {
  padding: 20px;
}

.blog-grid .blog-info h5 {
  font-size: 22px;
  font-weight: 700;
  margin: 0 0 10px;
}

.blog-grid .blog-info h5 a {
  color: #000;
}

.blog-grid .blog-info p {
  margin: 0;
}

.blog-grid .blog-info .btn-bar {
  margin-top: 20px;
}


/* Blog Sidebar
-------------------*/
.blog-aside .widget {
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}

.blog-aside .widget-body {
  padding: 15px;
}

.blog-aside .widget-title {
  padding: 15px;
  border-bottom: 1px solid #eee;
  background-color: #ffcc00;
}

.blog-aside .widget-title h3 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin: 0;
}

.blogwraparticle {
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  padding: 15px;
  margin: auto;
}

.blogWraper {
  margin: auto;
}

.blog-aside .widget-author .media {
  margin-bottom: 15px;
}

.blog-aside .widget-author p {
  font-size: 16px;
  margin: 0;
}

.blog-aside .widget-author .avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}

.blog-aside .widget-author h6 {
  font-weight: 600;
  color: #000;
  font-size: 20px;
  margin: 0;
  padding-left: 20px;
}

.blog-aside .widget-author h5 {
  font-weight: 600;
  color: #000;
  font-size: 22px;
  text-transform: capitalize;
}

.blog-aside .post-aside {
  margin-bottom: 15px;
}

.blog-aside .post-aside .post-aside-title h5 {
  margin: 0;
}

.blog-aside .post-aside .post-aside-title a {
  font-size: 18px;
  color: #000;
  font-weight: 600;
}

.blog-aside .post-aside .post-aside-meta {
  padding-bottom: 10px;
}

.blog-aside .post-aside .post-aside-meta a {
  color: #6F8BA4;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 10px;
}

.blog-aside .latest-post-aside+.latest-post-aside {
  border-top: 1px solid #eee;
  padding-top: 15px;
  margin-top: 15px;
}

.blog-aside .latest-post-aside .lpa-right {
  width: 90px;
}

.blog-aside .latest-post-aside .lpa-right img {
  border-radius: 3px;
}

.blog-aside .latest-post-aside .lpa-left {
  padding-right: 15px;
}

.blog-aside .latest-post-aside .lpa-title h5 {
  margin: 0;
  font-size: 15px;
}

.blog-aside .latest-post-aside .lpa-title a {
  color: #000;
  font-weight: 600;
}

.blog-aside .latest-post-aside .lpa-meta a {
  color: #6F8BA4;
  font-size: 12px;
  text-transform: capitalize;
  display: inline-block;
  margin-right: 10px;
}

.tag-cloud a {
  padding: 4px 15px;
  font-size: 13px;
  color: #ffffff;
  background: #000;
  border-radius: 3px;
  margin-right: 4px;
  margin-bottom: 4px;
}

.tag-cloud a:hover {
  background: #ffcc00;
}

.blog-blogsingle {
  padding-top: 30px;
  padding-bottom: 30px;
}

.article {
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  padding: 15px;
  margin: 15px 0 30px;
}

.article .article-title {
  padding: 15px 0 20px;
}

.article .article-title h6 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
}

.article .article-title h6 a {
  text-transform: uppercase;
  color: #ffcc00;
  border-bottom: 1px solid #ffcc00;
}

.article .article-title h2 {
  padding-top: 2px;
  color: #000;
  font-weight: 600;
  text-align: justify;
}

.article .article-title .media {
  padding-top: 15px;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 20px;
}

.article .article-title .media .avatar {
  padding-top: 2px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
}

.article .article-title .media .media-body {
  padding-left: 8px;
}

.article .article-title .media .media-body label {
  font-weight: 600;
  color: #ffcc00;
  margin: 0;
}

.article .article-title .media .media-body span {
  display: block;
  font-size: 12px;
}

.article .article-content h1,
.article .article-content h2,
.article .article-content h3,
.article .article-content h4,
.article .article-content h5,
.article .article-content h6 {
  color: #ffcc00;
  font-weight: 600;
  margin-bottom: 15px;
}

.article .article-content blockquote {
  max-width: 600px;
  padding: 15px 0 30px 0;
  margin: 0;
  border-left: 5px solid red;
}

.article .article-content blockquote p {
  padding: 8px;
  font-size: 20px;
  font-weight: 500;
  color: #ffcc00;
  margin: 0;
}

.article .article-content blockquote {
  color: #000;
  font-size: 16px;
}

.article .article-content blockquote cite {
  font-weight: 600;
}

.article .tag-cloud {
  padding-top: 10px;
}

img {
  max-width: 100%;
}

img {
  vertical-align: middle;
  border-style: none;
}


.blogbox {
  background-color: #FFFFFF;
  padding: 10px 20px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.blogbox h3 {
  margin: 30px 0 5px;
  font-weight: bold;
}

.blogbox ul {
  margin: 0;
}

.blogbox ul li {
  font-size: 13px;
  border-bottom: 1px dashed #DDDDDD;
  padding: 10px 0;
  font-weight: 600;
}

.blogbox ul li:last-child {
  border-bottom: 0;
}

.blogbox ul li i {
  font-size: 18px;
  margin-right: 20px;
}

.blogbox.categories ul li i {
  color: #BBBBBB;
  position: relative;
  top: 2px;
  width: 20px;
}

.blogbox.categories ul li i a {
  color: #000 !important;

}

.articlefooter {
  padding: 0 -1px 30px 30px
}


.articlefooter .articlestats {
  cursor: default;
  list-style: none;
  padding: 0;
  display: inline-block;
  margin: 0;
  line-height: 35px
}

.articlefooter .articlestats li {
  border-left: solid 1px rgba(160, 160, 160, 0.3);
  display: inline-block;
  font-weight: 400;
  letter-spacing: 0.25em;
  line-height: 1;
  margin: 0 0 0 2em;
  padding: 0 0 0 2em;
  text-transform: uppercase;
  font-size: 13px
}

.articlefooter .articlestats li a {
  color: #777
}

.articlefooter .articlestats li:first-child {
  border-left: 0;
  margin-left: 0;
  padding-left: 0
}


.share-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}


.react-share-button {
  display: inline-block;
  padding: 10px 20px !important;
  background-color: #000 !important;
  color: #fff !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  font-weight: bold;
}

.react-share-button:hover {
  background-color: #ffcc00 !important;
  color: #000 !important;
}


.react-share-button .icon {
  margin-right: 5px;
}

/* ========================== */

#image-container:hover {
  /*
  position: relative;
  transform: scale(1.1);
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2),0 6px 10px 0 rgba(0,0,0,0.3);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2),0 6px 10px 0 rgba(0,0,0,0.3);
  */
}

#image-container {
  position: relative;
  cursor: pointer;
}

.blogButton {
  --b: 3px;
  /* border thickness */
  --s: .54em;
  /* size of the corner */
  --color: white;

  padding: calc(.5em + var(--s)) calc(.9em + var(--s));
  color: #000000;
  --_p: var(--s);
  background:
    conic-gradient(from 90deg at var(--b) var(--b), #0000 90deg, var(--color) 0) var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
  transition: .3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: .16em;
  font-size: 18px;
  font-weight: bold;

  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.blogButton:hover,
.blogButton:focus-visible {
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: .10em;
}

.blogButton:active {
  background: black;
  color: #fff;
}

#image-text1 {
  position: absolute;
  bottom: 20px;
  left: 60px;
  text-align: center;
  /* background-color: rgba(0, 0, 0, 0.7); */
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
}

#image-text2 {
  position: absolute;
  bottom: 20px;
  left: 60px;
  text-align: center;
  /* background-color: rgba(0, 0, 0, 0.7); */
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
}

#image-text3 {
  position: absolute;
  bottom: 20px;
  left: 35px;
  text-align: center;
  /* background-color: rgba(0, 0, 0, 0.7); */
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
}

#image-text4 {
  position: absolute;
  bottom: 20px;
  left: 50px;
  text-align: center;
  /* background-color: rgba(0, 0, 0, 0.7); */
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
}

#image-text5 {
  position: absolute;
  bottom: 20px;
  left: 41px;
  text-align: center;
  /* background-color: rgba(0, 0, 0, 0.7); */
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
}