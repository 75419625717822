.category-card {
  background-color: #fff;
  color: #000;
  border: none;
  height: 40px;
  text-align: center;
  /* justify-content: center; */
  border-radius: 20px;
  cursor: pointer;
}

.category-card:hover {
  background-color: #000;
  color: #fff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.3);
}

.category-card-text {
  font-size: 25px;
  cursor: pointer;
  padding: 5px;
}

.upnametext {
  text-transform: capitalize;
}


/*
Timeline
*/

.page-timeline {
  margin: 15px auto 0 auto;
  position: relative;
  max-width: 1000px;
}

.page-timeline:before {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 25px;
  right: auto;
  height: 100%;
  width: 3px;
  background: #ffcc00;
  z-index: 0;

  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.3);
}

.page-timeline:after {
  position: absolute;
  content: '';
  width: 3px;
  height: 40px;
  background: #ffcc00;
  background: -webkit-linear-gradient(top, #ffcc00, rgba(52, 152, 219, 0));
  background: linear-gradient(to bottom, #ffcc00, rgba(52, 152, 219, 0));
  top: 100%;
  left: 25px;

  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.3);
}

.vtimeline-content {
  margin-left: 70px;
  background: #fff;
  border: 1px solid #e6e6e6;
  padding: 35px 20px;
  border-radius: 3px;
  /* text-align: left; */

  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.3);
}

.vtimeline-content h3 {
  font-size: 1.5em;
  font-weight: 600;
  display: inline-block;
  margin: 0;
}

.vtimeline-content p {
  font-size: 20px;
  margin: 0;
  color: #000;
}

.vtimeline-point {
  position: relative;
  display: block;
  vertical-align: top;
  margin-bottom: 30px;
}

.vtimeline-icon {
  position: relative;
  color: #fff;
  width: 50px;
  height: 50px;
  background: #ffcc00;
  border-radius: 50%;
  float: left;
  text-align: center;
  line-height: 50px;
  z-index: 99;
  /* margin-left: 280px; */
}

.vtimeline-icon i {
  display: block;
  font-size: 1.5em;
  line-height: 50px;

}

.vtimeline-date {
  width: 260px;
  text-align: right;
  position: absolute;
  left: 0;
  top: 10px;
  font-weight: 400;
  color: #000;
}

.post-meta {
  padding-top: 15px;
  margin-bottom: 20px;
}

.post-meta li:not(:last-child) {
  margin-right: 10px;
}

.post-meta h3 {
  font-family: "Montserrat", sans-serif;
  color: #252525;
  font-weight: 700;
  font-variant-ligatures: common-ligatures;
  margin-top: 0;
  letter-spacing: -0.2px;
  line-height: 1.3;
}

.mb20 {
  margin-bottom: 20px !important;
}

#blogcat {
  background: #ffcc00;
  height: 148px;
  border-radius: 12px;
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 36px;
}

/* ===============youtube crousel======= */
.exampleApp {
  position: relative;
  width: auto;
  margin: auto;
  /* overflow : hidden ; */
  margin-left: -9%;
  margin-right: -9%
}

.you-card {
  width: 400px;
  height: 300px;
}

.ytimeline-content {
  background: #fff;
  border: 1px solid #e6e6e6;
  padding: 20px 20px 0px 20px;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.3);
}

.vtimeline-content img {
  width: 100%;
  height: 300px !important;
  object-fit: cover;
  /* object-position: top; */
}

.btn-outline-primary {
  color: #000000 !important;
  background-color: #ffcc00 !important;
  border: none;
}

.btn-outline-primary:hover {
  color: #ffffff !important;
  background-color: #000 !important;
  border: none;
}

.btn-primary-1 {
  background-color: #ffcc00;
  color: #000;
  border: none;

}

.btn-primary-1:hover {
  background-color: #000;
  color: #fff;
}

.updateimages {
  width: 7rem;
  height: auto;
}

/* ========single book======= */
.bookcontent-container {
  max-height: 4.5em;
  /* Adjust this value to control the number of lines displayed */
  overflow: hidden;
}

.bookcontent {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Adjust this value to the number of lines you want to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.authtext {
  font-weight: bold;
}

.blogcontent-container {
  height: 54px;
  overflow: hidden;
}

.blogcontent {
  display: -webkit-box;
  -webkit-line-clamp: 9;
  /* Adjust this value to the number of lines you want to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shayaricontent-container {
  max-height: 4.5em;
  /* Adjust this value to control the number of lines displayed */
  overflow: hidden;
}

.shayaricontent {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* Adjust this value to the number of lines you want to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blogcontent-container {
  max-height: 5em;
  /* Adjust this value to control the number of lines displayed */
  overflow: hidden;
}

.blogcontent {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* Adjust this value to the number of lines you want to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blogtitlecontent-container {
  max-height: 4.5em;
  /* Adjust this value to control the number of lines displayed */
  overflow: hidden;
}

.blogtitlecontent {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Adjust this value to the number of lines you want to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shayaricontent-container2 {
  max-height: 5em;
  /* Adjust this value to control the number of lines displayed */
  overflow: hidden;
}

.shayaricontent2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Adjust this value to the number of lines you want to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ========View More button========== */
.readbutton {
  color: rgb(11, 9, 9);
  text-decoration: none;
  font-size: 25px;
  border: none;
  background: none;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  margin-top: 20px;
}

.readbutton::before {
  margin-left: auto;
}

.readbutton::after,
.readbutton::before {
  content: '';
  width: 0%;
  height: 2px;
  background: #ffcc00;
  display: block;
  transition: 0.5s;
}

.readbutton:hover::after,
.readbutton:hover::before {
  width: 100%;
}

.poetwraper {
  cursor: pointer;
}

.poetcard-name:first-child {
  text-transform: capitalize;

}

.TabWraper {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.TabWraperBox {
  width: fit-content;
  margin-right: 12px;
  /* margin: auto; */

}

.profileTabbtn {
  display: inline-block;
  cursor: pointer;
  transition: .5s ease all;
  width: fit-content;
  color: rgb(11, 9, 9);
  text-decoration: none;
  font-size: 20px;
  border: none;
  background: none;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  margin-top: 20px;
  padding: 8px 14px;
  /* background-color: aqua; */
}

.profileTabbtn::before {
  margin-left: auto;
}

.profileTabbtn::after,
.profileTabbtn::before {
  content: '';
  width: 0%;
  height: 2px;
  background: #ffcc00;
  display: block;
  transition: 0.5s;
}

.profileTabbtn:hover::after,
.profileTabbtn:hover::before {
  width: 100%;
}

.catlitile a {
  color: #ffcc00 !important;
  font-weight: 800;
}

.catlitile a span {
  color: #000 !important;
  font-weight: 800;
}


.poetcard-footer-border {
  border-right: 1px solid white;
  padding-right: 2px !important;
}


/* ===========forget password======= */
.forgetbody {
  margin: auto;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #ffcc00;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 496px;
  width: 496px;
  margin-top: 24px;
  border-radius: 16px;
}

.forgetcontainer {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  width: 448px;
}

.forform-container {
  max-width: 448px;
  margin: 0 auto;
}

.forgetbody h1 {
  color: black;
  font-size: 24px;
  margin-bottom: 10px;
}

.forgetbody p {
  color: black;
  font-size: 14px;
}

.forgetbody form {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.forgetbody input {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid black;
  border-radius: 5px;
  outline: none;
}

.forgetbody button {
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s;
}

.forgetbody button:hover {
  background-color: #333;
}

.back-to-login {
  font-size: 14px;
  margin-top: 10px;
}

.back-to-login a {
  text-decoration: none;
  color: black;
}

.back-to-login a:hover {
  text-decoration: underline;
}

/* ========whatsapp button============ */
.vtimeline-content-btn {
  margin-left: 70px;
  padding: 35px 20px;
  border-radius: 3px;
  /* text-align: left; */

}

.sendbutton {
  width: 100%;
  display: inline-block;
  padding: 10px 20px;
  background-color: #1f231f;
  color: white;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-in-out;
}

.sendbutton:hover {
  background-color: #ffcc00;
  color: #fff;
  transform: scale(1.1);
}


@media (max-width: 576px) {
  .sendbutton {
    width: 100%;
    margin-bottom: 10px;
  }
}

.badge-image {
  width: 30px;
  height: auto;
  margin-left: 0px;
}


/*======================
    404 page
=======================*/


.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: 'Arvo', serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {

  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}


.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #000 !important;
  padding: 10px 20px;
  background: #ffcc00;
  margin: 20px 0;
  display: inline-block;
}

.link_404:hover {
  color: #fff !important;
  padding: 10px 20px;
  background: #000;
}

.contant_box_404 {
  margin-top: -50px;
}

/*======================
    email verified
=======================*/
.enailverify-body {
  font-family: Arial, sans-serif;
  background-color: #FFcc00;
  /* Yellow background */
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.enailverifycontainer {
  text-align: center;
  background-color: #FFFFFF !important;
  /* White container */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.success-icon {
  font-size: 50px;
  color: #000000;
  /* Black success icon */
}

.enailverify-body h1 {
  color: #000000;
  /* Black heading text */
}

.enailverify-body p {
  color: #000000;
  /* Black paragraph text */
}

.enailverify-body a {
  color: #FFcc00;
  /* Yellow link text */
  text-decoration: none;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
}

.success-emoji {
  font-size: 48px;
  margin-top: -4px;
}

#commentname {
  color: #000;
  text-transform: capitalize !important;
}

.badgeimagz {
  width: 20px;
  height: 20px;
}