/* ---------testimonial----------- */
.carousel-root {
  width: 64% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  color: rgb(212, 32, 32);
  height: 100%;

}

.carousel .slide img {
  width: 120px !important;
  height: 120px !important;
  border-radius: 50%;
}

.myCarousel {
  background: white;
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  box-shadow: 5px;
  height: 286px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  padding-top: 8px;
  /* margin-bottom: 4px; */
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}


.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #070606;
  font-size: 16px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #ffcc00;
  font-size: 30px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #ffcc00;
  font-size: 30px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #000 !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 50px !important;
  width: 50px !important;
  position: absolute;
  top: 45% !important;
  border-radius: 50%;
}

.testimonialApp {
  margin-top: 100px;
  text-align: center;
  background-color: #ffcc00;
}

.text-testi {
  color: #000;
  padding-top: 32px;
  padding-bottom: 32px;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }

  .myCarousel {
    background: #fff;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #161313;
    height: 269px;
  }

  .carousel .slide img {
    width: 24% !important;
    border-radius: 50%;
    border: 1px dotted;
    border-color: #fff;
    margin-bottom: 5px;
  }
}

.testh1 {
  padding-top: 20px;
  text-decoration: none;
  border: 0px;

}

/* ---------testimonial----------- */