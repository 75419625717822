
  
  .comment-main{
    height: 1030px;
    overflow: auto;
  }


  .comment-container{
    height: 250px;
    overflow: auto;

  }

  .comment-inputbox{
    position: fixed;
  }

  /* ===================================== */
  .replybutton {
    font-family: inherit;
    font-size: 20px;
    background: #ffcc00;
    color: #000;
    padding: 0.4em 1em;
    padding-left: 0.4em;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 16px;
    overflow: hidden;
    transition: all 0.2s;
    margin-left: 5px;
  }
  
  .replybutton span {
    display: block;
    margin-left: 0.3em;
    transition: all 0.3s ease-in-out;
  }
  
  .replybutton svg {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
  }
  
  .replybutton:hover .svg-wrapper {
    animation: fly-1 0.6s ease-in-out infinite alternate;
  }
  
  .replybutton:hover svg {
    transform: translateX(1.2em) rotate(45deg) scale(1.1);
  }
  
  .replybutton:hover span {
    transform: translateX(5em);
  }
  
  .replybutton:active {
    transform: scale(0.95);
  }
  
  @keyframes fly-1 {
    from {
      transform: translateY(0.1em);
    }
  
    to {
      transform: translateY(-0.1em);
    }
  }

  .replydiv{
    display: flex;
    height: 50px;
  }
  
  .repinput{
    border-radius: 5px;
    border:1px solid;
  }